import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Gap, Section, SEO, Post } from '../components'
import { extractNodesFromData } from '../lib/helpers'

const openedArticles = 5

class News extends React.Component {
  render() {
    const { pathname, data } = this.props

    const { posts, contentImages } = extractNodesFromData(data)

    const openPosts = posts.slice(0, openedArticles)
    const restPosts = posts.slice(openedArticles, posts.length)

    return (
      <>
        <SEO
          title="News | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Gap.Top />
        <Section background="black">
          {openPosts.map((post, key) => (
            <Fragment key={post.slug}>
              <Post post={post} contentImages={contentImages} />
            </Fragment>
          ))}
          <Post.MorePosts posts={restPosts} />
        </Section>
      </>
    )
  }
}

export default News

export const pageQuery = graphql`
  query {
    contentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
      edges {
        node {
          ...PostContentImage
          relativePath
        }
      }
    }

    posts: allPosts(
      sort: { fields: createdAt, order: DESC }
      filter: { state: { eq: "published" } }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
`
